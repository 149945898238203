import { Col, Container, Row } from 'react-bootstrap'
import BannerBg from './images/banner.png'
import iconOne from './images/1.svg'
import iconTwo from './images/2.svg'
import iconThree from './images/3.svg'
import './banner.css'
import { useEffect, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Navigate } from 'react-router-dom';


const Banner = ({eventsParent, setEventsParent}) => {

  const [busqueda, setBusqueda] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (input) => {
    setInputValue(input);
  }

  function handleChange(selected) {
    console.log(selected)
    if (selected.length > 0) {
      setBusqueda(selected[0]);
    } else {
      setBusqueda('');
    }
  }

  function handleSubmit() {
    if (busqueda !== undefined && busqueda !== "") {
      setRedirect(true);
    }
  }

  if (redirect) {
    if (busqueda.venta_externa !== null) {
      return <Navigate to={"/espaciozity/" + busqueda.id} />;
    } else {
      return <Navigate to={"/entradas/"+busqueda.id} />;
    }
  }

  return (
    <section className='banner-section' style={{ backgroundImage: `url(${BannerBg})` }}>
      <Container>
        <Row>
          <Col xl={8}>
            <div className='banner-content'>
              <h1>Taquilla Central</h1>
              <div className='banner-search-box'>
                <form>
                  <Row>
                    <Col xl={10}>
                    <Typeahead
                      id="buscador-suggest"
                      labelKey="nombre_evento"
                      options={eventsParent}
                      maxResults={4}
                      paginationText="Mostrar más resultados..."
                      placeholder="Buscar..."
                      onChange={handleChange}
                      onInputChange={handleInputChange}
                    />
                    </Col>
                    <Col xl={2}>
                      <button type='submit' onClick={()=>handleSubmit()}>
                        <i className='fas fa-search'></i>
                      </button>
                    </Col>
                  </Row>
                  {/*<Row>
                    <Col xl={8}>
                      <div className='search-items'>
                        <div className='search-item'>
                          <h4>
                            <img src={iconOne} alt='' /> Categoría
                          </h4>
                          <select>
                            <option value='Conciertos'>Conciertos</option>
                            <option value='Conciertos'>Conciertos</option>
                            <option value='Conciertos'>Conciertos</option>
                            <option value='Conciertos'>Conciertos</option>
                          </select>
                        </div>
                        <div className='search-item'>
                          <h4>
                            <img src={iconTwo} alt='' />
                            Ubicación
                          </h4>
                          <select>
                            <option value='Almería province'>Almería province</option>
                            <option value='Almería province'>Almería province</option>
                            <option value='Almería province'>Almería province</option>
                            <option value='Almería province'>Almería province</option>
                          </select>
                        </div>
                        <div className='search-item'>
                          <h4>
                            <img src={iconThree} alt='' />
                            Fecha
                          </h4>
                          <select>
                            <option value='Esta semana'>Esta semana</option>
                            <option value='Esta semana'>Esta semana</option>
                            <option value='Esta semana'>Esta semana</option>
                            <option value='Esta semana'>Esta semana</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                  </Row>*/}
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
