import CustomerDetails from '../components/CustomerDetails/CustomerDetails'
import EventDetails from '../components/EventDetails/EventDetails'
import PageBanner from '../components/PageBanner/PageBanner'
import { useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';

const CustomerDetailsPage = () => {
  const [id, setID] = useState("");
  const [event, setevent] = useState ([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  //Get Event
  useEffect(()=>{
    const id_ev = JSON.parse(localStorage.getItem('id_evento'));
    setID(id_ev)
    if(id !== undefined){
      fetch('https://taquillacentral.com/api/event/' + id)
        .then(result => {
          if (result.ok) {
            return result.json();
          }
          throw new Error('No hay entradas disponibles');      
        })
        .then((res) => {
          if (Array.isArray(res) && res.length > 0) { // Comprobamos que la respuesta sea un array y tenga al menos un objeto
            const modifiedRes = res.map((event) => {
              return { ...event, onBuy: 0 }; // Agregamos la propiedad onBuy con el valor de 0 a cada elemento del array
            });
            setevent(modifiedRes[0]); // Accedemos a la propiedad id_evento del primer objeto del array
          } else {
            throw new Error('La respuesta de la API no es un array o está vacía'); // Si la respuesta no es un array o está vacía, lanzamos un error
          }
          console.log(res)
        })
        .catch(res => {})
      }
  },[id]);
  return (
    <>
      {/*<PageBanner event={event}/>
      <EventDetails event={event}/>*/}
      <CustomerDetails />
    </>
  )
}

export default CustomerDetailsPage
