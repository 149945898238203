import { useState} from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import Calendar from './images/calendar.svg'
import Pointer from './images/pointer.svg'
import './event.css'
import thumbnail from './thumb.png'
import { useEffect } from 'react'

const Event = ({ column, nombre, fecha, precio, url_imagen, id_evento, lugar, venta_externa, grupo }) => {
  const [toValue, setToValue] = useState("")
  useEffect(() => {
    if (venta_externa !== null && venta_externa !== "") {
      setToValue("/espaciozity/" + nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/-/g, " ").replace(/\s+/g, '-') + "-" + id_evento);
    } else if (grupo == 1){
      setToValue("/grupo-eventos/" + nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/-/g, " ").replace(/\s+/g, '-') + "-" + id_evento);
    }
    else {
      setToValue("/entradas/" + nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/-/g, " ").replace(/\s+/g, '-') + "-" + id_evento);
    }
  }, [id_evento]);

  return (
    <>
      <Col xl={column} lg={6} md={6}>
        <div className={`event-item ${column == 3 ? 'small-event' : 'large-event'}`}>
          <div className='event-image'>
            <div
              className='event-image-thumbnail'
              style={{
                backgroundImage: `url(${url_imagen})`,
              }}
            ></div>
            <span className='event-date'>
              <img src={Calendar} alt='' /> {fecha}
            </span>
          </div>
          <div className='event-content'>
            <h4>{nombre}</h4>
            <p className='location'>
              <img src={Pointer} alt='' /> {lugar}
            </p>
            <div className='event-meta'>
              <div className='price'>
                <p>Desde</p>
                <span>€{parseFloat(precio).toFixed(2)}</span>
              </div>
              <Link className='cart-button' to={toValue}>
                <i className='fas fa-shopping-cart'></i> Comprar
              </Link>
            </div>
          </div>
        </div>
      </Col>
    </>
  )
}

export default Event
