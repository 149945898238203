import React, { useEffect, useState } from 'react'
import Banner from '../components/Banner/Banner'
import FeaturedEvents from '../components/FeaturedEvents/FeaturedEvents'
import EventList from '../components/EventList/EventList'

const HomePage = () => {

  const [eventsParent,setEventsParent] = useState ([]);

  return (
    <>
      <Banner eventsParent={eventsParent} setEventsParent={setEventsParent} />
      <FeaturedEvents />
      <EventList eventsParent={eventsParent} setEventsParent={setEventsParent}/>
    </>
  )
}

export default HomePage
