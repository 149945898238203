import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PageBannerBg from './bg.png'
import './pagebanner.css'
import moment from 'moment';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const PageBanner = ({ event }) => {
  const {fecha1, fecha2} = ("")
  if (typeof fecha1 === 'string' && typeof fecha2 === 'string') {
    if(event !== undefined){
      const fecha1 = event.apertura_Puertas.split(" ")[0];
      console.log(fecha1)
      const fecha2 = event.cierre_Puertas.split(" ")[0]}
      console.log(fecha2)
  }
  
  return (
    <section className='page-banner-section' style={{ backgroundImage: `url(${PageBannerBg})` }}>
      <Container>
        <Row className='align-items-center'>
          <Col lg={8}>
            <div className='breadcrumb-text'>
              <p>{ fecha1 === fecha2 ? ((new Date (event.fecha)).toLocaleDateString("es-ES", options)).replace(/^./,((new Date (event.fecha)).toLocaleDateString("es-ES", options)).charAt(0).toUpperCase() ) : "Desde el "+((new Date (event.fecha)).toLocaleDateString("es-ES", options)).replace(/^./,((new Date (event.fecha)).toLocaleDateString("es-ES", options)).charAt(0).toUpperCase() )}</p>
              <h1>{event.nombre_evento}</h1>
            </div>
          </Col>
          <Col lg={4} className='text-end'>
            <div className='page-banner-button'>
              <Link to='/'>
                <i className='fas fa-angle-left'></i> Volver
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PageBanner
