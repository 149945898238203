import { useState, useEffect} from 'react'
import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap'
import Event from '../Event/Event'
import './eventlist.css'
import triangle from './triangle.svg'

const EventList = ({eventsParent,setEventsParent}) => {
  const [dropDown, setDropDown] = useState('')
  const [selected, setSelected] = useState('Próximos')
  const [events, setevents] = useState ([]);

  const handleSelect = () => {
    if (dropDown === true) {
      setDropDown(false)
    } else {
      setDropDown(true)
    }
  }

  const handleChangeDropdown = (option) => {
    setSelected(option);

    switch (option) {
      case 'Alfabeticamente, A-Z':
        setevents(eventsParent.sort((a, b) => (a.nombre_evento > b.nombre_evento) ? 1 : -1));
        break;

      case 'Alfabeticamente, Z-A':
        setevents(eventsParent.sort((a, b) => (a.nombre_evento < b.nombre_evento) ? 1 : -1));
        break;

      case 'Próximos':
        setevents(eventsParent.sort((a, b) => ((new Date(a.fecha)) > (new Date(b.fecha))) ? 1 : -1));
        break;
      default:
        break;
    }
      setDropDown(false);
  }

  //Get Events
  useEffect(()=>{
    localStorage.removeItem("id_sesion");
    localStorage.removeItem("entradas");
    localStorage.removeItem("id_evento");
    localStorage.removeItem("path");
    localStorage.removeItem("group_path");
    fetch('https://taquillacentral.com/api/events')
      .then(result => {
        if (result.ok) {
          return result.json();
        }
        throw new Error('No hay entradas disponibles');      
      })
      .then((res) => {
        res.forEach((node) => node.onBuy = 0);
        setevents(res);
        setEventsParent(res);
        console.log(res)
        })
      .catch(res => {})
  },[]);


  return (
    <>
      <section className='eventlist-section'>
        <Container>
          <Row className='section-details'>
            <Col md={8}>
              <div className='section-title'>
                <h2>Todos los eventos</h2>
              </div>
            </Col>
            <Col md={4}>
              <div className='select-custom'>
                <div onClick={handleSelect} className={`select-button ${dropDown && 'active'}`}>
                  {selected} <i className={`fas fa-angle-${dropDown === true ? 'up' : 'down'}`}></i>
                </div>
                {dropDown && (
                  <div className='select-options'>
                    <img src={triangle} alt='' />
                    <div className='select-option' onClick={()=>handleChangeDropdown('Próximos')}>Próximos</div>
                    <div className='select-option' onClick={()=>handleChangeDropdown('Alfabeticamente, A-Z')}>Alfabeticamente, A-Z</div>
                    <div className='select-option' onClick={()=>handleChangeDropdown('Alfabeticamente, Z-A')}>Alfabeticamente, Z-A</div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className='g-4'>
          {Array.from(events).map((item, idx) => (
            <Event column='3' key={item.id} nombre={item.nombre_evento} fecha={item.fecha} precio={item.min_precio} url_imagen={item.url_imagen} id_evento={item.id} lugar={item.lugar} venta_externa={item.venta_externa}/>))}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default EventList
