import EventListGroup from '../components/EventListGroup/EventListGroup'
import { useParams } from 'react-router-dom';
import { useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';

const EventGroupListPage = () => {
  const { id } = useParams();
  const [event, setevent] = useState ([]);
  const [event_group, seteventGroup] = useState ([]);
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);


  useEffect(()=>{
    fetch('https://taquillacentral.com/api/event-group/' + id.split("-")[id.split("-").length - 1])
      .then(result => {
        if (result.ok) {
          return result.json();
        }
        throw new Error('No hay entradas disponibles');      
      })
      .then((res) => {   
        console.log(res)
        setevent(res.eventos);
        seteventGroup(res.grupo_evento[0])
        console.log(event) 
      })
      .catch(res => {})
  },[id]);
  return (
    <>
      {/*<PageBannerGroup event={event_group}/>
      <EventDetailsGroup event={event_group}/>*/}
      <EventListGroup event={event}/> 
      {/*<NextEvents />*/}
    </>
  )
}

export default EventGroupListPage
