import ErrorMessage from '../components/ErrorMessage/ErrorMessage'




const ErrorBuyPage = () => {
  return (
    <>
      <ErrorMessage />
    </>
  )
}

export default ErrorBuyPage
