import { Col, Container, Row } from 'react-bootstrap'
import { useState, useEffect} from 'react'
import Event from '../Event/Event'
import './featured.css'

const FeaturedEvents = () => {
  const [events, setevents] = useState ([]);
  
  //Get Events
  useEffect(()=>{
    fetch('https://taquillacentral.com/api/important_events')
      .then(result => {
        if (result.ok) {
          return result.json();
        }
        throw new Error('No hay entradas disponibles');      
      })
      .then((res) => {
        res.forEach((node) => node.onBuy = 0);
        setevents(res);
        console.log(res);
        })
      .catch(res => {})
  },[]);
  return (
    <>
      <section className='event-section'>
        <Container>
          <Row className='section-details'>
            <Col md={6}>
              <div className='section-title'>
                <h2>Eventos destacados</h2>
              </div>
            </Col>
          </Row>
          <Row className='g-4'>
          {Array.from(events).map((item, idx) => (
            <Event column='4' key={item.id} nombre={item.nombre_evento} fecha={item.fecha} precio={item.min_precio} url_imagen={item.url_imagen} id_evento={item.id} lugar={item.lugar} venta_externa={item.venta_externa}/>))}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default FeaturedEvents
