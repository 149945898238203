import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import HomePage from './pages/HomePage'
import TicketListPage from './pages/TicketListPage'
import TicketListOwnPage from './pages/TicketListOwnPage'
import CustomerDetailsPage from './pages/CustomerDetailsPage'
import ThankYouPage from './pages/ThankYouPage'
import ErrorBuyPage from './pages/ErrorBuyPage'
import EventGroupListPage from './pages/EventGroupListPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import AvisoLegalPage from './pages/AvisoLegalPage'
import PoliticaCookiesPage from './pages/PoliticaCookiesPage'
import TerminosPage from './pages/TerminosPage'

function App() {
  return (
    <div className='App'>
      <Router>
        {/*<Header />*/}
        <Routes>
          {/*<Route path='/' element={<HomePage />} />
          <Route path='/espaciozity/:id' element={<TicketListPage />} />*/}
          <Route path='/entradas/:id' element={<TicketListOwnPage />} />
          <Route path='/datos-del-comprador' element={<CustomerDetailsPage />} />
          <Route path='/compra-correcta' element={<ThankYouPage />} />
          <Route path='/grupo-eventos/:id' element={<EventGroupListPage />} />
          {/*<Route path='/politica-privacidad' element={<PrivacyPolicyPage />} />
          <Route path='/aviso-legal' element={<AvisoLegalPage />} />
          <Route path='/politica-cookies' element={<PoliticaCookiesPage />} />
          <Route path='/terminos' element={<TerminosPage />} />*/}
          <Route path='/compra-erronea' element={<ErrorBuyPage />} />
        </Routes>
        {/*<Footer />*/}
      </Router>
    </div>
  )
}

export default App
