import { useState, useEffect} from 'react'
import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap'
import Event from '../Event/Event'
import './eventlist.css'
import triangle from './triangle.svg'

const EventListGroup = ({event}) => {

  //Get Events
  useEffect(()=>{
    localStorage.removeItem("id_sesion");
    localStorage.removeItem("entradas");
    localStorage.removeItem("id_evento");
    localStorage.removeItem("path");

    const pathname = window.location.pathname; // Obtiene la parte de la URL después del dominio
    const parts = pathname.split('/'); // Divide la parte de la URL en segmentos separados por '/'
    const desiredPart1 = parts[parts.length - 2]; // Obtiene el penúltimo segmento de la URL
    const desiredPart2 = parts[parts.length - 1]; // Obtiene el último segmento de la URL

    const desiredParte = desiredPart1 + '/' + desiredPart2;
    console.log(desiredParte); // Muestra "las-playas-zaragoza-1d7310" en la consola
    localStorage.setItem('path', JSON.stringify(desiredParte));
  },[]);


  return (
    <>
      <section className='eventlist-section'>
        <Container>
          {/*<Row className='section-details'>
            <Col md={8}>
              <div className='section-title'>
                <h2>Todos los eventos</h2>
              </div>
            </Col>
  </Row>*/}
          <Row className='g-4'>
          {Array.from(event).map((item, idx) => (
            <Event column='3' key={item.id} nombre={item.nombre_evento} fecha={item.fecha} precio={item.min_precio} url_imagen={item.url_imagen} id_evento={item.id} lugar={item.lugar} venta_externa={item.venta_externa} grupo={item.grupo}/>))}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default EventListGroup
